import React from "react"

import { Card, CardBody, Col, Row } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import moment from "moment"
import PropTypes from "prop-types"
import MereneHodnoty from "./components/MereneHodnoty"

const WelcomeComp = props => {
  const history = useHistory()
  const { data, id, allData } = props
  let isDanes = false

  if (!allData?.uniwan || !allData?.uniwan[id]) {
    isDanes = true
  }

  let lastUpdate = moment().format("DD.MM.yy")
  if (isDanes) {
    lastUpdate = moment(allData?.danes[data?.unique_id]?.date).format("DD.MM")
  } else {
    lastUpdate = moment(allData?.uniwan[data?.unique_id]?.date).format("DD.MM")
  }
  const dataData = isDanes
    ? allData?.danes[data?.unique_id]
    : allData?.uniwan[data?.unique_id]

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-white p-3">
                <h5 className="text-white">Basic information</h5>
                <p>Station: {data?.name}</p>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="12">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">{lastUpdate}</h5>
                    <p className="text-muted mb-0">Last update</p>
                  </Col>
                  <Col xs="6">
                    <Row>
                      <Col xs={12}>
                        <h5 className="font-size-15">
                          {isDanes
                            ? allData?.danes[data?.unique_id]?.pocetMereni
                            : allData?.uniwan[data?.unique_id]?.pocetMereni}
                        </h5>
                        <p className="text-muted mb-0">
                          Number of measurements
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xs={12}>
                    <p className="text-muted mb-0">
                      <strong>Last measured values</strong>
                    </p>
                    <MereneHodnoty
                      allData={dataData}
                      data={data}
                      id={id}
                      isDanes={isDanes}
                    />
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link
                    onClick={() => history.push(`/charts/${id}/${isDanes}`)}
                    className="btn btn-primary  btn-sm"
                  >
                    View detail
                    <i className="mdi mdi-arrow-right ms-1" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

WelcomeComp.propTypes = {
  data: PropTypes.any,
  allData: PropTypes.any,
  id: PropTypes.any,
}
export default WelcomeComp
