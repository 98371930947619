import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import { isAdmin } from "../../store/LocalData"

const Navbar = props => {
  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/dashboard" className="nav-link arrow-none">
                    <i className="bx bx-home-circle me-2" />
                    {props.t("Dashboard")} {props.menuOpen}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/profile" className="nav-link arrow-none">
                    <i className="bx bx-user me-2" />
                    {props.t("Profile")} {props.menuOpen}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/items" className="nav-link arrow-none">
                    <i className="bx bx-list-check me-2" />
                    {props.t("Your items")} {props.menuOpen}
                  </Link>
                </li>
                {isAdmin && (
                  <>
                    <li className="nav-item">
                      <Link to="/all-items" className="nav-link arrow-none">
                        <i className="bx bx-list-ul me-2" />
                        {props.t("Items")} {props.menuOpen}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/groups" className="nav-link arrow-none">
                        <i className="bx bx-list-ul me-2" />
                        {props.t("Items group")} {props.menuOpen}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/subjects" className="nav-link arrow-none">
                        <i className="bx bx-list-ol me-2" />
                        {props.t("Subjects")} {props.menuOpen}
                      </Link>
                    </li>
                  </>
                )}

                {/*<li className="nav-item ">*/}
                {/*  <Link to="/boxs" className="nav-link arrow-none">*/}
                {/*    <i className="bx bx-tone me-2" />*/}
                {/*    {props.t("Vaše zařízení")}*/}
                {/*  </Link>*/}
                {/*</li>*/}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
