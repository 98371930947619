import Select from "react-select"
import PropTypes from "prop-types"
import React, { useState } from "react"
import toastr from "toastr"
import ClientData from "../../../store/Client"
import { AccessToken } from "../../../store/LocalData"

const SubjectGroupComponent = props => {
  const { data } = props
  const [selectedMulti, setselectedMulti] = useState(data.groups)

  const showToast = (
    message = "success",
    type = "info",
    title = "Action save"
  ) => {
    toastr.options = {
      positionClass: "toast-top-right",
    }
    toastr.options = {
      positionClass: "toast-top-right",
      closeButton: false,
      debug: false,
      timeOut: 3000,
      progressBar: false,
      newestOnTop: true,
      showEasing: false,
      hideEasing: false,
      showDuration: 300,
      hideDuration: 1000,
    }
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }

  const handleMulti = async selectedMulti => {
    setselectedMulti(selectedMulti)

    await ClientData()
      .post(
        `subjects/groups?access_token=${AccessToken}&id=${data?.data?.id}`,
        {
          SubjectGroups: selectedMulti,
        }
      )
      .then(res => {
        if (res.data.success) {
          showToast()
        }
        if (!res.data.success) {
          showToast("Error, contact administrator", "warning")
        }
      })
  }

  return (
    <>
      {data?.data?.id && (
        <Select
          value={selectedMulti}
          isMulti={true}
          onChange={e => {
            handleMulti(e)
          }}
          options={data.allGroups}
          classNamePrefix="select2-selection"
        />
      )}
    </>
  )
}

export default SubjectGroupComponent
SubjectGroupComponent.propTypes = {
  data: PropTypes.any,
}
