import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import ChartsPage from "../pages/Charts/index"
import Items from "../pages/Items/Items"
import AllItems from "../pages/Items/AllItems"
import Subjects from "../pages/Subjects/Subjects"
import EditGroup from "../pages/Groups/EditGroup"
import Groups from "../pages/Groups/Groups"
import EditItem from "../pages/Items/EditItem"
import EditSubject from "../pages/Subjects/EditSubject"
import ProfilePage from "../pages/Profile/ProfilePage"
import ResetPasswordPage from "../pages/Authentication/ResetPassword"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/items", component: Items },
  { path: "/all-items", component: AllItems },
  { path: "/subjects", component: Subjects },
  { path: "/edit-subject/:id", component: EditSubject },
  { path: "/edit-subject", component: EditSubject },
  { path: "/groups", component: Groups },
  { path: "/charts/:id/:itemType", component: ChartsPage },
  { path: "/charts/:id", component: ChartsPage },
  { path: "/edit-group/:id", component: EditGroup },
  { path: "/edit-group", component: EditGroup },
  { path: "/edit-item/:id", component: EditItem },
  { path: "/edit-item", component: EditItem },

  // //profile
  { path: "/profile", component: ProfilePage },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password-request/:hash", component: ResetPasswordPage },
  { path: "/reset-password-request", component: ResetPasswordPage },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
