import React, { useCallback, useState } from "react"
import { Button, Col, Row } from "reactstrap"
import PropTypes from "prop-types"
import moment from "moment"
import { value } from "lodash/seq"

const DateFromToComponent = props => {
  const { dates, onChangeDate, setChangeDate } = props
  const [dateButton, setDateButton] = useState(1)

  const onSetButton = useCallback(
    (value, data) => {
      setDateButton(Number(value))
      setChangeDate(data)
    },
    [value]
  )

  return (
    <Row>
      <Col md={5}>
        <Button
          style={{ background: dateButton === 1 ? "#ffffff" : "#999999" }}
          className="mb-2"
          onClick={() =>
            onSetButton(1, moment().add(-30, "days").format("YYYY-MM-DD"))
          }
        >
          Last 30 days
        </Button>{" "}
        <Button
          style={{ background: dateButton === 2 ? "#ffffff" : "#999999" }}
          onClick={() =>
            onSetButton(2, moment().add(-14, "days").format("YYYY-MM-DD"))
          }
          className="mb-2"
        >
          Last 14 days
        </Button>{" "}
        <Button
          style={{ background: dateButton === 3 ? "#ffffff" : "#999999" }}
          onClick={() =>
            onSetButton(3, moment().add(-7, "days").format("YYYY-MM-DD"))
          }
          className="mb-2"
        >
          Last 7 days
        </Button>{" "}
        <Button
          style={{ background: dateButton === 4 ? "#ffffff" : "#999999" }}
          onClick={() =>
            onSetButton(4, moment().add(-3, "days").format("YYYY-MM-DD"))
          }
          className="mb-2"
        >
          Last 3 days
        </Button>{" "}
        <Button
          style={{ background: dateButton === 5 ? "#ffffff" : "#999999" }}
          onClick={() =>
            onSetButton(5, moment().add(-1, "day").format("YYYY-MM-DD"))
          }
          className="mb-2"
        >
          Last 1 days
        </Button>{" "}
      </Col>

      <Col md={6}>
        <Row>
          <Col lg={6}>
            <Row className="mb-3">
              <label
                htmlFor="example-date-input"
                className="col-md-2 col-form-label"
              >
                From
              </label>
              <div className="col-md-10">
                <input
                  className="form-control"
                  type="date"
                  onChange={e => onChangeDate("from", e.currentTarget.value)}
                  id="fromDate"
                  defaultValue={dates.from}
                />
              </div>
            </Row>
          </Col>
          <Col lg={6}>
            <Row className="mb-3">
              <label
                htmlFor="example-date-input"
                className="col-md-2 col-form-label"
              >
                to
              </label>
              <div className="col-md-10">
                <input
                  className="form-control"
                  type="date"
                  defaultValue={dates.to}
                  onChange={e => onChangeDate("to", e.currentTarget.value)}
                  id="toDate"
                />
              </div>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

DateFromToComponent.propTypes = {
  dates: PropTypes.object,
  onChangeDate: PropTypes.func,
  setChangeDate: PropTypes.func,
}

export default DateFromToComponent
