import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"
import { ErrorMessage, Field, Formik } from "formik"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/Logo_new.png"
import * as Yup from "yup"
import ClientData from "../../store/Client"
import { AccessToken } from "../../store/LocalData"
import toastr from "toastr"

const FormValidateSchema = Yup.object({
  email: Yup.string()
    .min(6, "Minimum charts is 4")
    .email("E-mail in not correct format!")
    .required("This field is required"),
})

const ForgetPasswordPage = props => {
  const initialValues = {
    email: "",
  }

  const showToast = (
    message = "success",
    type = "info",
    title = "Email with information send..."
  ) => {
    toastr.options = {
      positionClass: "toast-top-right",
    }
    toastr.options = {
      positionClass: "toast-top-right",
      closeButton: false,
      debug: false,
      timeOut: 3000,
      progressBar: false,
      newestOnTop: true,
      showEasing: false,
      hideEasing: false,
      showDuration: 300,
      hideDuration: 1000,
    }
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }

  const onSave = async values => {
    await ClientData()
      .post(`site/reset-password`, {
        PasswordResetRequestForm: values,
      })
      .then(res => {
        if (res.data.success) {
          showToast()
        } else {
          showToast("Email not found. This user not registred.", "error")
        }
      })
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Forget Password | OPV systems</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={FormValidateSchema}
                      onSubmit={async values => {
                        await onSave(values)
                      }}
                      render={({ values, isSubmitting, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <Field
                              name="email"
                              type="text"
                              label="Your e-mail"
                              className="form-control"
                              placeholder="Enter your email"
                            />
                            <ErrorMessage name="email" />
                          </div>
                          <Row className="mb-3">
                            <Col className="text-end">
                              <button
                                className="btn btn-primary w-md "
                                type="submit"
                              >
                                Reset
                              </button>
                            </Col>
                          </Row>
                        </form>
                      )}
                    />
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} All Rights Reserved </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
