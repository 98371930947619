import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/img_avatar.png"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import getApiSubjectItemData from "../../store/API/GetApiSubjectItemData"
import { AccessToken } from "../../store/LocalData"
import toastr from "toastr"
import ClientData from "../../store/Client"

const UserProfile = props => {
  const { data, loading, mutate } = getApiSubjectItemData({
    access_token: AccessToken,
    id: id,
  })

  const showToast = (
    message = "success",
    type = "info",
    title = "Action save"
  ) => {
    toastr.options = {
      positionClass: "toast-top-right",
    }
    toastr.options = {
      positionClass: "toast-top-right",
      closeButton: false,
      debug: false,
      timeOut: 3000,
      progressBar: false,
      newestOnTop: true,
      showEasing: false,
      hideEasing: false,
      showDuration: 300,
      hideDuration: 1000,
    }
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }

  const onSave = async values => {
    await ClientData()
      .post(`subjects/edit?access_token=${AccessToken}`, {
        Users: values,
      })
      .then(res => {
        if (res.data.success) {
          showToast()
          if (values.id === undefined) {
            history.push("/subjects")
          }
        }
        if (!res.data.success) {
          showToast("Error, check your username... must be unique", "warning")
        }
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | OPV systems</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}

          <Row>
            <Col md="6" className="offset-md-3">
              <Breadcrumb title="OPV" breadcrumbItem="Profile" />
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <Media>
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>
                          {formData.firstName} {formData.secondName}
                        </h5>
                        <p className="mb-1">{formData.username}</p>
                        <p className="mb-0">{formData.phone}</p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>

              <h4 className="card-title mb-4">Edit profile information</h4>

              <Card>
                <CardBody>
                  <AvForm
                    className=""
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v)
                    }}
                  >
                    <Row>
                      <Col md={12}>
                        <div className="form-group">
                          <AvField
                            name="username"
                            label="User Name"
                            value={formData.username}
                            className="form-control"
                            placeholder="Enter User Name"
                            type="text"
                            required
                            disabled
                          />
                          <AvField name="idx" value={idx} type="hidden" />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <AvField
                            name="firstName"
                            onChange={e =>
                              onChangeInput("firstName", e.currentTarget.value)
                            }
                            label="Name"
                            value={formData.firstName}
                            className="form-control"
                            placeholder="Enter your Name"
                            type="text"
                            required
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <AvField
                            name="secondName"
                            onChange={e =>
                              onChangeInput("secondName", e.currentTarget.value)
                            }
                            label="Last name"
                            value={formData.secondName}
                            className="form-control"
                            placeholder="Enter your last name"
                            type="text"
                            reqired
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <AvField
                            onChange={e =>
                              onChangeInput("phone", e.currentTarget.value)
                            }
                            name="tel"
                            label="Phone"
                            value={formData.phone}
                            className="form-control"
                            placeholder="Enter your phone number"
                            type="text"
                            reqired
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="form-group">
                          <AvField
                            name="password"
                            label="Passsword"
                            value=""
                            className="form-control"
                            placeholder="Enter your paswwrod"
                            type="text"
                            reqired
                          />
                        </div>
                      </Col>
                      <div className="text-center mt-4">
                        <Button type="submit" color="danger">
                          Update
                        </Button>
                      </div>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
