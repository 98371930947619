import useSWR from "swr"

// import productFetcher from 'utils/api/product';
const fetcher = url => fetch(url).then(res => res.json())

const BaseUrl = "https://apiopv.cz/web/"

export default function getApiSubjectData(dataObject) {
  const { data, mutate, error } = useSWR(
    `${BaseUrl}subjects?access_token=${dataObject.id}`,
    fetcher
  )

  const loading = !data && !error
  // const categories = data?.filter((current: any) => current.type === type);
  // const paginatedData = data?.slice(offset, limit);
  // const loggedOut = error && error.status === 403;

  return {
    loading,
    error,
    data,
    // loggedOut,
    // user: data,
    mutate,
  }
}
