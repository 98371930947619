import React from "react"

import { Card, CardBody, Col, Row } from "reactstrap"
import { Link, useHistory } from "react-router-dom"

import avatar1 from "../../../assets/images/users/img_avatar.png"
import profileImg from "../../../assets/images/profile-img.png"
import { AccessToken } from "../../../store/LocalData"
import getApiUserData from "../../../store/API/GetApiUserData"

const WelcomeUserComp = () => {
  const history = useHistory()

  const { data, loading, mutate } = getApiUserData({
    access_token: AccessToken,
  })

  if (!data || loading) {
    return <>...loading...</>
  }
  // if (!data.success && data.error === "login") {
  //   history.push("/logout")
  // }

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-white p-3">
                <h5 className="text-white">Welcome!</h5>
                <p>OPV DASHBOARD</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />
              </div>
              <h5 className="font-size-15 text-truncate">
                {data.data?.firstName} {data.data?.secondName}
              </h5>
              <p className="text-muted mb-0 text-truncate">
                {data.data?.username}{" "}
              </p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">{data?.nItems}</h5>
                    <p className="text-muted mb-0">Number of device</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">&nbsp;</h5>
                    <p className="text-muted mb-0"></p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link
                    className="btn btn-primary  btn-sm"
                    onClick={() => history.push("/profile")}
                  >
                    Show Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeUserComp
