import axios from "axios"

const ClientData = () => {
  //\ __API__ = http://api.service.com
  let url = process.env.BASE_URL
  return axios.create({ baseURL: "https://apiopv.cz/web/" })
}
export default ClientData

export const FetcherGet = url =>
  ClientData()
    .get(url)
    .then(res => res.data.data)
