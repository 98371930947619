import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import OnlySimpleLineChart from "./OnlySimpleLineChart"

const UniwanSimpleLineChart = props => {
  let data = []
  const { chartData } = props

  const getData = key => {
    const d = chartData.data.filter(item => item.value_id === key)

    return (data = d.map(({ value, value_id, timestamp }) => {
      return {
        name: moment(timestamp).format("DD.MM"),
        [value_id]: value,
        data: value,
      }
    }))
  }

  const getMin = key => {
    let d = Number(chartData.min[`${key}`]).toFixed(4)

    return Number(d).toFixed(4)
  }
  const getMax = key => {
    let d = Number(chartData.max[`${key}`])
    return Number(d).toFixed(4)
  }

  return (
    <div>
      <OnlySimpleLineChart
        chartData={getData("outer temperature")}
        dataKey="outer temperature"
        title="Outer temperature (°C)"
        collor="#f94144"
        min={getMin("outer temperature")}
        max={getMax("outer temperature")}
      />
      <OnlySimpleLineChart
        chartData={getData("inner temperature")}
        dataKey="inner temperature"
        title="Inner temperature (°C)"
        collor="#f3722c"
        min={getMin("inner temperature")}
        max={getMax("inner temperature")}
      />
      <OnlySimpleLineChart
        chartData={getData("inner 2nd temperature")}
        dataKey="inner 2nd temperature"
        title="Inner 2nd temperature (°C)"
        min={getMin("inner 2nd temperature")}
        max={getMax("inner 2nd temperature")}
        collor="#f8961e"
      />
      <OnlySimpleLineChart
        chartData={getData("humidity")}
        dataKey="humidity"
        title="Humidity (%)"
        collor="#f9c74f"
      />
      {/*<OnlySimpleLineChart
        chartData={getData("sensor")}
        dataKey="sensor"
        title="sensor"
        collor="#90be6d"
        min={getMin("sensor")}
        max={getMax("sensor")}
      />
      <OnlySimpleLineChart
        chartData={getData("vin")}
        dataKey="vin"
        title="vin"
        collor="#43aa8b"
        min={getMin("vin")}
        max={getMax("vin")}
      />*/}
      <OnlySimpleLineChart
        chartData={getData("pressure")}
        dataKey="pressure"
        title="Pressure (hPa)"
        collor="#577590"
        min={getMin("pressure")}
        max={getMax("pressure")}
      />
      <OnlySimpleLineChart
        chartData={getData("hladina")}
        dataKey="hladina"
        title="Grounwater level (m)"
        collor="#577590"
        min={getMin("hladina")}
        max={getMax("hladina")}
      />
    </div>
  )
}

UniwanSimpleLineChart.propTypes = {
  chartData: PropTypes.any,
}
export default UniwanSimpleLineChart
