import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../../../client/src/components/Common/Breadcrumb"
import moment from "moment"
import DateFromToComponent from "../Dashboard/components/DateFromToComponent"
import { useHistory, useParams } from "react-router-dom"
import PropTypes from "prop-types"
import ChartsComponent from "./components/ChartsComponent"
import getItemApiData from "../../store/API/GetItemApiData"

const ChartsPage = props => {
  const { id, itemType } = useParams()
  const history = useHistory()

  if (!itemType) {
    history.push("../../../")
  }

  const [accessToken, setAccessToken] = useState(
    JSON.parse(localStorage.getItem("authUser")).access_token
  )

  const [dates, setDates] = useState({
    from: moment().subtract(1, "month").format("y-MM-DD"),
    to: moment().format("y-MM-DD"),
  })

  const { data, loading, mutate } = getItemApiData({
    id: accessToken,
    from: dates.from,
    to: dates.to,
    itemId: id,
    itemType: itemType,
  })

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setAccessToken(obj.access_token)
    }
  }, [])

  const onChangeDate = (variant, date) => {
    setDates(prevState => ({
      ...prevState,
      [variant]: date,
    }))
  }

  const setChangeDate = dateFrom => {
    console.log(dateFrom)
    setDates(prevState => ({
      ...prevState,
      from: dateFrom,
      dateTo: moment().format("YYYYY-MM-DD"),
    }))
  }

  const setDays = (variant, date) => {
    setDates(prevState => ({
      ...prevState,
      [variant]: date,
    }))
  }

  return (
    <Row>
      <Col className="page-content">
        <MetaTags>
          <title>Charts | OPV systems</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title={"dashboards"} breadcrumbItem={"Charts"} />

          <DateFromToComponent
            dates={dates}
            onChangeDate={onChangeDate}
            setChangeDate={setChangeDate}
          />
          {!data || loading ? (
            <Row>
              <Col
                lg={12}
                style={{ marginTop: 30, textAlign: "center", color: "white" }}
              >
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg={12} style={{ marginTop: 30 }}>
                <ChartsComponent data={data.data} id={id} itemType={itemType} />
              </Col>
            </Row>
          )}
        </Container>
      </Col>
    </Row>
  )
}

ChartsPage.propTypes = {
  match: PropTypes.any,
}
export default ChartsPage
