import MetaTags from "react-meta-tags"
import React from "react"
import { Alert, Card, CardBody, Col, Container, Media, Row } from "reactstrap"

// availity-reactstrap-validation

//redux

import { useHistory, withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/img_avatar.png"
// actions
import getApiSubjectItemData from "../../store/API/GetApiSubjectItemData"
import { AccessToken, userId } from "../../store/LocalData"
import toastr from "toastr"
import ClientData from "../../store/Client"
import ProfileForm from "./ProfileForm"

const ProfilePage = props => {
  const history = useHistory()
  const { data, loading, mutate } = getApiSubjectItemData({
    access_token: AccessToken,
    id: userId,
  })

  const showToast = (
    message = "success",
    type = "info",
    title = "Action save"
  ) => {
    toastr.options = {
      positionClass: "toast-top-right",
    }
    toastr.options = {
      positionClass: "toast-top-right",
      closeButton: false,
      debug: false,
      timeOut: 3000,
      progressBar: false,
      newestOnTop: true,
      showEasing: false,
      hideEasing: false,
      showDuration: 300,
      hideDuration: 1000,
    }
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }

  const onSave = async values => {
    await ClientData()
      .post(`subjects/profile`, {
        Users: values,
        access_token: AccessToken,
      })
      .then(res => {
        if (res.data.success) {
          showToast()
          localStorage.setItem("authUser", JSON.stringify(res.data.data))
          history.push("./dashboard")
        }
        if (!res.data.success) {
          showToast("Error, check your username... must be unique", "warning")
        }
      })
  }

  if (!data || loading) {
    return <>....</>
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | OPV systems</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}

          <Row>
            <Col md="6" className="offset-md-3">
              <Breadcrumb title="OPV" breadcrumbItem="Profile" />

              <Card>
                <CardBody>
                  <Media>
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <Media body className="align-self-center">
                      <div className="text-muted">
                        <h5>
                          {data.data.firstName} {data.data.secondName}
                        </h5>
                        <p className="mb-1">{data.data.username}</p>
                        <p className="mb-0">{data.data.phone}</p>
                      </div>
                    </Media>
                  </Media>
                </CardBody>
              </Card>

              <h4 className="card-title mb-4">Edit profile information</h4>

              <ProfileForm data={data} onSave={onSave} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ProfilePage)
