import { Button, Col, Row } from "reactstrap"
import { ErrorMessage, Field, Formik } from "formik"
import React from "react"
import * as Yup from "yup"
import PropTypes from "prop-types"

const FormValidateSchema = Yup.object({
  name: Yup.string()
    .min(4, "Minimu charts is 4")
    .required("This field is required"),
})

const GroupEditForm = props => {
  const { onSave, data } = props
  const initialValues = {
    name: data?.data?.name,
    id: data?.data?.id,
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FormValidateSchema}
      onSubmit={async values => {
        await onSave(values)
      }}
      render={({ values, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <div className="form-group">
                <label htmlFor="name">Group name</label>
                <Field
                  name="name"
                  type="text"
                  label="Name"
                  className="form-control"
                  placeholder="Enter group Name"
                />
                <ErrorMessage name="name" />
                <Field name="id" type="hidden" />
              </div>
            </Col>
            <div className="text-center mt-4">
              <Button type="submit" color="success">
                Save
              </Button>
            </div>
          </Row>
        </form>
      )}
    />
  )
}
export default GroupEditForm
GroupEditForm.propTypes = {
  onSave: PropTypes.func,
  data: PropTypes.any,
}
