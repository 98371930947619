import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "../../../../client/src/components/Common/Breadcrumb"
import moment from "moment"
import getApiData from "../../store/API/GetApiData"
import WelcomeComp from "../../../../client/src/pages/Dashboard/WelcomeComp"
import WelcomeUserComp from "./components/WelcomeUserComp"

import MapComponent from "./components/MapComponent"

const Dashboard = props => {
  const [accessToken, setAccessToken] = useState(
    JSON.parse(localStorage.getItem("authUser")).access_token
  )

  const [dates, setDates] = useState({
    from: moment().subtract(1, "month").format("y-MM-DD"),
    to: moment().format("y-MM-DD"),
  })

  const { data, loading, mutate } = getApiData({
    id: accessToken,
    from: dates.from,
    to: dates.to,
  })

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setAccessToken(obj.access_token)
    }
  }, [])

  const onChangeDate = (variant, date) => {
    setDates(prevState => ({
      ...prevState,
      [variant]: date,
    }))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | OPV systems</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title={"Dashboards"} breadcrumbItem={"Dashboard"} />
          {!data || loading ? (
            <Row>
              <Col
                lg={12}
                style={{ marginTop: 30, textAlign: "center", color: "white" }}
              >
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col xl={4} md={4}>
                  <WelcomeUserComp />
                </Col>
              </Row>

              <Row>
                {data.data.items.map((itemName, indexLogger) => (
                  <Col xl="6" key={indexLogger}>
                    <WelcomeComp
                      data={itemName}
                      allData={data.data}
                      id={itemName.unique_id}
                    />
                  </Col>
                ))}
              </Row>
              <Row className="mt-2 mb-4">
                <Col xs={12}>
                  <MapComponent data={data.data.items} />
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
