import React from "react"
import { compose, withProps } from "recompose"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyDNuCNBI6A5UB9t_-qrYY7OlNb2VNQ0eIU&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `800px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{
      lat: Number(props.data[0]?.location_x),
      lng: Number(props.data[0]?.location_y),
    }}
  >
    {props.isMarkerShown && (
      <>
        {props.data.map((item, index) => (
          <Marker
            key={index}
            position={{
              lat: Number(item?.location_x),
              lng: Number(item?.location_y),
            }}
            title={item.name}
            onClick={() =>
              props.onClickMarker(item.unique_id, item.name_for_api)
            }
          />
        ))}
      </>
    )}
  </GoogleMap>
))

const MapComponent = props => {
  const { data } = props
  const history = useHistory()
  const markers = data.filter(
    item => item.location_x !== null || item.location_x !== ""
  )
  const onClickMarker = (id, name) => {
    let isDanes = false
    if (name === "DANES") {
      isDanes = true
    }
    history.push(`../../charts/${id}/${isDanes}`)
  }
  return (
    <>
      <MyMapComponent
        isMarkerShown
        data={markers}
        onClickMarker={onClickMarker}
      />
    </>
  )
}

MapComponent.propTypes = {
  data: PropTypes.any,
}
export default MapComponent
