import { Button, Col, Row } from "reactstrap"
import { ErrorMessage, Field, Formik } from "formik"
import React, { useState } from "react"
import * as Yup from "yup"
import PropTypes from "prop-types"
import Switch from "react-switch"

const FormValidateSchema = Yup.object({
  username: Yup.string()
    .min(4, "Minimu charts is 4")
    .required("This field is required")
    .email("E-mail není ve správném tvaru"),
  firstName: Yup.string()
    .min(3, "Minimu charts is 3")
    .required("This field is required"),
  secondName: Yup.string()
    .min(3, "Minimu charts is 3")
    .required("This field is required"),
  subject: Yup.string()
    .min(3, "Minimu charts is 3")
    .required("This field is required"),
})

const SubjectEditForm = props => {
  const { onSave, data } = props
  const initialValues = {
    username: data?.data?.username,
    id: data?.data?.id,
    firstName: data?.data?.firstName,
    secondName: data?.data?.secondName,
    password: "",
    phone: data?.data?.phone,
    subject: data?.data?.subject,
    isAdmin: data?.data?.isAdmin,
  }

  const [switch1, setswitch1] = useState(!!data?.data?.isAdmin)

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FormValidateSchema}
      onSubmit={async values => {
        await onSave(values)
      }}
      render={({ values, isSubmitting, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <Field
                  name="username"
                  type="email"
                  label="User Name"
                  className="form-control"
                  placeholder="Enter User Name"
                />
                <ErrorMessage name="username" />
                <Field name="id" type="hidden" />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="form-group">
                <label htmlFor="subject">Subject name</label>
                <Field
                  name="subject"
                  type="text"
                  label="Subject name"
                  className="form-control"
                  placeholder="Enter subject name"
                />
                <ErrorMessage name="subject" />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="form-group">
                <label htmlFor="firstName">First name</label>
                <Field
                  label="First name"
                  type="text"
                  name="firstName"
                  className="form-control"
                  placeholder="Enter your Name"
                />
                <ErrorMessage name="firstName" />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="form-group">
                <label htmlFor="secondName">Second name</label>
                <Field
                  label="Last name"
                  type="text"
                  name="secondName"
                  className="form-control"
                  placeholder="Enter your last name"
                />{" "}
                <ErrorMessage name="secondName" />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <Field
                  label="Phone"
                  type="text"
                  name="phone"
                  className="form-control"
                  placeholder="Enter your phone number"
                />
                <ErrorMessage name="phone" />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Field
                  label="Password"
                  name="password"
                  className="form-control"
                  placeholder="Set your password"
                  type="password"
                />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="form-group">
                <label htmlFor="password">is admin?</label>
                <Switch
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  className="me-1 mb-sm-8 mb-2"
                  onColor="#626ed4"
                  onChange={() => {
                    setFieldValue("isAdmin", !switch1)
                    setswitch1(!switch1)
                  }}
                  checked={switch1}
                />
              </div>
              <Field type="hidden" name="isAdmin" />
            </Col>
            <div className="text-center mt-4">
              <Button type="submit" color="success">
                Save
              </Button>
            </div>
          </Row>
        </form>
      )}
    />
  )
}
export default SubjectEditForm
SubjectEditForm.propTypes = {
  onSave: PropTypes.func,
  data: PropTypes.any,
}
