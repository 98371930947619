import React from "react"
import PropTypes from "prop-types"
import WelcomeComp from "../WelcomeComp"
import { Col, Row } from "reactstrap"

const MereneHodnoty = props => {
  const { data, id, allData, isDanes } = props

  const setLabel = name => {
    switch (name) {
      case "H1":
        return "Groundwater level(m)"
      case "KCM":
        return "Conductivity (mS/cm)"
      case "RV":
        return "Humidity (%)"
      case "SRA1H":
        return "Precipitation 1hod (mm)"
      case "_T":
        return "Air temperature (oC)"
      case "_TX":
        return "Temperature (oC)"
      case "hladina":
        return "Grounwater level (m)"
      case "pressure":
        return "Pressure (hPa)"
      case "humidity":
        return "Humidity (%)"
      case "temperature":
        return "Temperature (°C)"
      case "inner 2nd temperature":
        return "Inner 2nd temperature (°C)"
      case "inner temperature":
        return "Inner 2nd temperature (°C)"
      case "outer temperature":
        return "Outer temperature (°C)"
    }
    return name
  }

  const names = [
    "Groundwater level(m)",
    "Conductivity (mS/cm)",
    "Humidity (%RV)",
    "Precipitation 1hod (mm)",
    "Air temperature (oC)",
    "Temperature (oC)",
  ]

  let d = ["H1", "KCM", "RV", "SRA1H", "_T", "_TX"]
  if (!isDanes) {
    d = [
      "humidity",
      "inner 2nd temperature",
      "inner temperature",
      "outer temperature",
      "pressure",
      /* "sensor",
      "vin",*/
      "hladina",
    ]
  }
  return (
    <Row>
      <Col xs={12}>
        <Row>
          <Col>&nbsp;</Col>
          {d.map((item, key) => (
            <>
              {allData?.max[item] !== null && (
                <Col
                  key={`min${key}`}
                  className="text-center"
                  style={{ lineHeight: "12px", marginBottom: 8 }}
                >
                  <strong style={{ fontSize: 11, lineHeight: "12px" }}>
                    {setLabel(item)}
                  </strong>
                </Col>
              )}
            </>
          ))}
        </Row>
        <Row>
          <Col>
            <strong style={{ fontSize: 11 }}>Max:</strong>
          </Col>
          {d.map((item, key) => (
            <>
              {allData?.max[item] !== null && (
                <Col key={`min${key}`} className="text-center">
                  {allData?.max[item]}
                </Col>
              )}
            </>
          ))}
        </Row>
        <Row>
          <Col>
            <strong style={{ fontSize: 11 }}>Min:</strong>
          </Col>
          {d.map((item, key) => (
            <>
              {allData?.min[item] !== null && (
                <Col
                  key={`min${key}`}
                  className="text-center"
                  style={{ lineHeight: "12px", marginBottom: 8 }}
                >
                  {allData?.min[item]}
                </Col>
              )}
            </>
          ))}
        </Row>
      </Col>
    </Row>
  )
}

MereneHodnoty.propTypes = {
  data: PropTypes.any,
  allData: PropTypes.any,
  id: PropTypes.any,
  isDanes: PropTypes.any,
}

export default MereneHodnoty
