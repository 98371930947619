import React from "react"
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import PropTypes from "prop-types"

const OnlySimpleLineChart = props => {
  const { chartData, title, dataKey, collor = "#8884d8", min, max } = props
  if (!chartData.length) {
    return <></>
  }
  const renderColorfulLegendText = value => {
    return <span style={{ color: "#ffffff" }}>{title}</span>
  }

  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <LineChart
          data={chartData}
          margin={{
            top: 30,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#999" color="#ffffff" />
          <XAxis dataKey="name" name="date" tick={{ fill: "#fff" }} />
          <YAxis
            dataKey={dataKey}
            attributeName="outer temperature"
            domain={[Number(min), Number(max)]}
            tick={{ fill: "#fff" }}
            allowDataOverflow={true}
            interval="preserveStartEnd"
          />
          <Tooltip />
          <Legend
            formatter={renderColorfulLegendText}
            verticalAlign="top"
            height={50}
          />
          <Line
            type="monotone"
            dataKey={dataKey}
            stroke={collor}
            activeDot={{ r: 8 }}
            color="#ffffff"
            interval="preserveStartEnd"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
OnlySimpleLineChart.propTypes = {
  title: PropTypes.any,
  dataKey: PropTypes.any,
  chartData: PropTypes.any,
  collor: PropTypes.any,
  min: PropTypes.any,
  max: PropTypes.any,
}
export default OnlySimpleLineChart
