import MetaTags from "react-meta-tags"
import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Media,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

// availity-reactstrap-validation
//redux
import { useParams, withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/img_avatar.png"
import { AccessToken } from "../../store/LocalData"
import ClientData from "../../store/Client"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import classnames from "classnames"
import EditItemForm from "./EditItemForm"
import getApiItemData from "../../store/API/GetApiItemData"

const EditItem = props => {
  const { id } = useParams()
  const [verticalActiveTabWithIcon, setverticalActiveTabWithIcon] =
    useState("1")

  const { data, loading, mutate } = getApiItemData({
    access_token: AccessToken,
    id: id,
  })

  if (!data || loading) {
    return <>loading</>
  }

  const toggleVerticalIcon = tab => {
    if (verticalActiveTabWithIcon !== tab) {
      setverticalActiveTabWithIcon(tab)
    }
  }

  const showToast = (
    message = "success",
    type = "info",
    title = "Action save"
  ) => {
    toastr.options = {
      positionClass: "toast-top-right",
    }
    toastr.options = {
      positionClass: "toast-top-right",
      closeButton: false,
      debug: false,
      timeOut: 3000,
      progressBar: false,
      newestOnTop: true,
      showEasing: false,
      hideEasing: false,
      showDuration: 300,
      hideDuration: 1000,
    }
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }

  const onSave = async values => {
    await ClientData()
      .post(`items/edit?access_token=${AccessToken}`, {
        Items: values,
      })
      .then(res => {
        if (res.data.success) {
          showToast()
        }
      })
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <MetaTags>
            <title>Edit item | OPV systems</title>
          </MetaTags>
          <Row>
            <Col md="8" className="offset-md-2">
              <Breadcrumb title="Dashboard" breadcrumbItem="Edit item" />

              <h4 className="card-title mb-4">Edit item information</h4>

              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <Nav className="flex-column vertical-icon">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTabWithIcon === "1",
                            })}
                            onClick={() => {
                              toggleVerticalIcon("1")
                            }}
                          >
                            <i className="fas fa-home"></i> Basic information
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTabWithIcon === "2",
                            })}
                            onClick={() => {
                              toggleVerticalIcon("2")
                            }}
                          >
                            <i className="fas fa-user"></i> Item in groups
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTabWithIcon === "3",
                            })}
                            onClick={() => {
                              toggleVerticalIcon("3")
                            }}
                          >
                            <i className="fab fa-facebook-messenger"></i> Item
                            in subjects
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    <Col md="9">
                      <TabContent
                        activeTab={verticalActiveTabWithIcon}
                        className="text-muted mt-4 mt-md-0"
                      >
                        <TabPane tabId="1">
                          <EditItemForm onSave={onSave} data={data} />
                        </TabPane>
                        <TabPane tabId="2">
                          <p>in progress</p>
                        </TabPane>
                        <TabPane tabId="3">
                          <p>in progress</p>
                        </TabPane>

                        <TabPane tabId="4">
                          <p>in progress</p>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default withRouter(EditItem)
