import MetaTags from "react-meta-tags"
import React, { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

// availity-reactstrap-validation
//redux
import { useHistory, useParams, withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import { AccessToken } from "../../store/LocalData"
import ClientData from "../../store/Client"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import classnames from "classnames"
import GroupEditForm from "./GroupEditForm"
import getApiGroupItemData from "../../store/API/GetApiGroupItemData"

const EditGroup = props => {
  const { id } = useParams()
  const history = useHistory()
  const [verticalActiveTabWithIcon, setverticalActiveTabWithIcon] =
    useState("1")
  const { data, loading, mutate } = getApiGroupItemData({
    access_token: AccessToken,
    id: id,
  })

  if (!data || loading) {
    return <>loading</>
  }

  const toggleVerticalIcon = tab => {
    if (verticalActiveTabWithIcon !== tab) {
      setverticalActiveTabWithIcon(tab)
    }
  }

  const showToast = (
    message = "success",
    type = "info",
    title = "Action save"
  ) => {
    toastr.options = {
      positionClass: "toast-top-right",
    }
    toastr.options = {
      positionClass: "toast-top-right",
      closeButton: false,
      debug: false,
      timeOut: 3000,
      progressBar: false,
      newestOnTop: true,
      showEasing: false,
      hideEasing: false,
      showDuration: 300,
      hideDuration: 1000,
    }
    if (type === "info") toastr.info(message, title)
    else if (type === "warning") toastr.warning(message, title)
    else if (type === "error") toastr.error(message, title)
    else toastr.success(message, title)
  }

  const onSave = async values => {
    await ClientData()
      .post(`groups/edit?access_token=${AccessToken}`, {
        Groups: values,
      })
      .then(res => {
        if (res.data.success) {
          showToast()
          if (values.id === undefined) {
            history.push("/groups")
          }
        }
      })
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <MetaTags>
            <title>Edit group | OPV systems</title>
          </MetaTags>
          <Row>
            <Col md="8" className="offset-md-2">
              <Breadcrumb title="Dashboard" breadcrumbItem="Edit group" />

              <h4 className="card-title mb-4">Edit group information</h4>

              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <Nav className="flex-column vertical-icon">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTabWithIcon === "1",
                            })}
                            onClick={() => {
                              toggleVerticalIcon("1")
                            }}
                          >
                            <i className="fas fa-home"></i> Basic information
                          </NavLink>
                        </NavItem>

                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              "mb-2": true,
                              active: verticalActiveTabWithIcon === "3",
                            })}
                            onClick={() => {
                              toggleVerticalIcon("3")
                            }}
                          >
                            <i className="fab fa-facebook-messenger"></i> Items
                            in group
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Col>
                    <Col md="9">
                      <TabContent
                        activeTab={verticalActiveTabWithIcon}
                        className="text-muted mt-4 mt-md-0"
                      >
                        <TabPane tabId="1">
                          <GroupEditForm onSave={onSave} data={data} />
                        </TabPane>
                        <TabPane tabId="3">
                          <p>in progress</p>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default withRouter(EditGroup)
