import React, { useState } from "react"
import MetaTags from "react-meta-tags" // Added Meta Tag npm Package
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Modal,
  NavLink,
  Row,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AccessToken } from "../../store/LocalData"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import { useHistory } from "react-router-dom"
import getApiItemsData from "../../store/API/GetApiItemsData"

const Items = () => {
  const history = useHistory()
  const { data, loading, mutate } = getApiItemsData({
    id: AccessToken,
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  if (!data || loading) {
    return <>loading</>
  }
  const loadData = data.data

  const columns = [
    {
      dataField: "name",
      text: "Name",
      align: "left",
      headerAlign: "left",
      sort: true,
      isDummyField: true,
      formatter: (cellContent, row) => {
        return <>{row.name}</>
      },
    },
    {
      dataField: "serial_nr",
      text: "Serial NO.",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "unique_id",
      text: "Unique id",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "location_name",
      text: "Location name",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      dataField: "location_group",
      text: "Location group",
      align: "left",
      headerAlign: "left",
      sort: true,
    },
    {
      align: "left",
      headerAlign: "center",
      text: "actions",
      style: {
        width: "200px",
      },
      sort: false,
      isDummyField: true,
      formatter: (cellContent, row) => {
        return (
          <>
            <NavLink
              className="d-inline"
              onClick={() => history.push(`/edit-item/${row.id}`)}
            >
              <i className="mdi mdi-pencil me-2"></i> Edit{" "}
              <span className="ml-1 float-end"></span>
            </NavLink>{" "}
          </>
        )
      },
    },
  ]

  // Table Data

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ]

  const pageOptions = {
    sizePerPage: 5,
    totalSize: loadData.length, // replace later with size(customers),
    custom: true,
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: loadData.length },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Items | OPV systems</title>
        </MetaTags>
        <Container fluid={true}>
          <Row>
            <Col md={12}>
              <Breadcrumbs title="Items" breadcrumbItem="All subjects" />
              <Card>
                <CardBody>
                  <CardTitle className="text-white">
                    List all subjects
                  </CardTitle>
                  <CardSubtitle className="mb-3 text-white">
                    if you do not have the equipment here, contact the IT
                    administrator
                  </CardSubtitle>
                  <Row>
                    <Col sm={12} className="text-start align-content-end mt-3">
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={columns}
                        data={loadData}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={loadData}
                            search
                          >
                            {toolkitProps => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar
                                          {...toolkitProps.searchProps}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-30">
                                  <Col className="inner-custom-pagination d-flex">
                                    <div className="d-inline">
                                      <SizePerPageDropdownStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                    <div className="text-md-right ms-auto">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={isModalOpen}
        toggle={() => {
          toggleModal()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Modal Heading
          </h5>
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Overflowing text to show scroll behavior</h5>
          <p>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
            ac consectetur ac, vestibulum at eros.
          </p>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor.
          </p>
          <p>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
            cursus magna, vel scelerisque nisl consectetur et. Donec sed odio
            dui. Donec ullamcorper nulla non metus auctor fringilla.
          </p>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              toggleModal()
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button type="button" className="btn btn-primary ">
            Save changes
          </button>
        </div>
      </Modal>
    </>
  )
}

export default Items
