import { all, call, fork, put, takeEvery } from "redux-saga/effects"

//Account Redux states
import {
  REGISTER_USER,
  REGISTER_USER_FAILED,
  REGISTER_USER_SUCCESSFUL,
} from "./actionTypes"
import { registerUserFailed, registerUserSuccessful } from "./actions"

//Include Both Helper File with needed methods
import { postJwtRegister } from "../../../helpers/fakebackend_helper"
import { apiError } from "../login/actions"

// initialize relavant method of both Auth

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  const response = yield call(postJwtRegister, {
    SignupForm: {
      email: user.username,
      password: user.password,
      firstName: user.firstName,
      secondName: user.secondName,
    },
  })
  try {
    if (!response.success) {
      if (response.errors.username[0]) {
        yield put(registerUserFailed(response.errors.username[0]))
      }
      if (response.errors.password[0]) {
        yield put(registerUserFailed(response.errors.password[0]))
      }
      if (response.errors.firstName[0]) {
        yield put(registerUserFailed(response.errors.firstName[0]))
      }
      if (response.errors.lastName[0]) {
        yield put(registerUserFailed(response.errors.lastName[0]))
      }
    }
    localStorage.setItem("authUser", JSON.stringify(response.data))
    const timer = setTimeout(() => {
      window.location.href = "/dashboard"
    }, 1000)
    yield put(registerUserSuccessful(response.data))
    return () => clearTimeout(timer)
  } catch (error) {
    yield put(apiError(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
  yield takeEvery(REGISTER_USER_FAILED, registerUser)
  yield takeEvery(REGISTER_USER_SUCCESSFUL, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
