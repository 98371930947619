import { all, call, fork, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CHARTS_DATA } from "./actionTypes"
import { apiFail, apiSuccess } from "./actions"

//Include Both Helper File with needed methods
import {
  getMonthlyData,
  getWeeklyData,
  getYearlyData,
} from "../../helpers/fakebackend_helper"
import { apiError, loginSuccess } from "../auth/login/actions"

function* getApiData({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(getApiData, {
        from: user.username,
        access_token: user.access_token,
        to: user.password,
      })
      if (!response.success) {
        if (response.errors.username) {
          yield put(apiError(response.errors.username))
        }
        if (response.errors.password) {
          yield put(apiError(response.errors.password))
        }
        return false
      }

      localStorage.setItem("authUser", JSON.stringify(response.data))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* getChartsData({ payload: periodType }) {
  try {
    var response
    if (periodType == "monthly") {
      response = yield call(getWeeklyData, periodType)
    }
    if (periodType == "yearly") {
      response = yield call(getYearlyData, periodType)
    }
    if (periodType == "weekly") {
      response = yield call(getMonthlyData, periodType)
    }

    yield put(apiSuccess(GET_CHARTS_DATA, response))
  } catch (error) {
    yield put(apiFail(GET_CHARTS_DATA, error))
  }
}

export function* watchGetChartsData() {
  yield takeEvery(GET_CHARTS_DATA, getChartsData)
}

function* dashboardSaga() {
  yield all([fork(watchGetChartsData)])
}

export default dashboardSaga
