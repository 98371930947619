import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/Logo_new.png"

const Register = props => {
  const dispatch = useDispatch()

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }))

  // handleValidSubmit
  const handleValidSubmit = values => {
    dispatch(registerUser(values))
  }

  useEffect(() => {
    dispatch(apiError(""))
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Registrace</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">
                          Registration to OPV systems
                        </h5>
                        <p>
                          The registration must be approved by the OPV systems
                          administrator.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {loading && (
                      <div id="preloader">
                        <div id="status">
                          <div className="spinner-chase">
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                            <div className="chase-dot" />
                          </div>
                        </div>
                      </div>
                    )}

                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Registrace proběhla v pořádku
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}
                      {!user && (
                        <>
                          <div className="mb-3">
                            <AvField
                              id="firstName"
                              name="firstName"
                              label="Your name"
                              className="form-control"
                              placeholder="Type your first name"
                              type="text"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              id="secondName"
                              name="secondName"
                              label="Second name"
                              className="form-control"
                              placeholder="Type your second name"
                              type="text"
                              required
                            />
                          </div>

                          <div className="mb-3">
                            <AvField
                              name="username"
                              label="E-mail"
                              type="email"
                              required
                              placeholder="type your e-mail"
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="password"
                              label="Heslo"
                              type="password"
                              required
                              placeholder="type your secret password"
                            />
                          </div>

                          <div className="mt-4">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Send registration
                            </button>
                          </div>
                        </>
                      )}
                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering, you agree to the terms{" "}
                          <Link to="#" className="text-primary">
                            OPV systems
                          </Link>
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Do you have user account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login here
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} All Rights Reserved </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
