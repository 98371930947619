import { CardBody, CardTitle } from "reactstrap"
import DanesSimpleLineChart from "../../AllCharts/rechart/DanesSimpleLineChart"
import React, { useState } from "react"
import PropTypes from "prop-types"
import UniwanSimpleLineChart from "../../AllCharts/rechart/UniwanSimpleLineChart"

const ChartsComponent = props => {
  const { data, id, itemType } = props

  return (
    <CardBody>
      <CardTitle className="mb-4">
        Preview data station: {data.item.name}
      </CardTitle>
      {itemType && <DanesSimpleLineChart chartData={data} />}
      {itemType !== "true" && <UniwanSimpleLineChart chartData={data} />}
    </CardBody>
  )
}
export default ChartsComponent
ChartsComponent.propTypes = {
  data: PropTypes.any,
  id: PropTypes.any,
  itemType: PropTypes.any,
}
