import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import OnlySimpleLineChart from "./OnlySimpleLineChart"

const DanesSimpleLineChart = props => {
  let data = []
  const { chartData } = props

  if (!chartData.data.length) {
    return <></>
  }

  const getData = key => {
    const d = chartData.data.filter(item => item.data_name === key)
    data = d.map(({ data_value, data_name, date }) => {
      return {
        name: moment(date).format("DD.MM"),
        [data_name]: Number(data_value).toFixed(4),
        data: Number(data_value).toFixed(4),
      }
    })
    return data
  }

  const getMin = key => {
    let d = Number(chartData.min[`${key}`]).toFixed(4)

    return Number(d).toFixed(4)
  }
  const getMax = key => {
    let d = Number(chartData.max[`${key}`])

    return Number(d).toFixed(4)
  }

  return (
    <div>
      <OnlySimpleLineChart
        chartData={getData("H1")}
        dataKey="H1"
        title="Groundwater level(m)"
        collor="#e63946"
        min={getMin("H1")}
        max={getMax("H1")}
        //collor="#f94144"
      />
      <OnlySimpleLineChart
        chartData={getData("_TX")}
        dataKey="_TX"
        title="Temperature(oC)"
        collor="#f3722c"
        min={getMin("_TX")}
        max={getMax("_TX")}
        //collor="#f3722c"
      />
      <OnlySimpleLineChart
        chartData={getData("KCM")}
        dataKey="KCM"
        title="Conductivity(mS/cm)"
        collor="#f8961e"
        min={getMin("KCM")}
        max={getMax("KCM")}
        //collor="#f8961e"
      />
      <OnlySimpleLineChart
        chartData={getData("POWER")}
        dataKey="POWER"
        title="Tension(V)"
        collor="#f9c74f"
        //collor="#f9c74f"
        min={getMin("POWER")}
        max={getMax("POWER")}
      />
      <OnlySimpleLineChart
        chartData={getData("_T")}
        dataKey="_T"
        title="Air temperature(oC)"
        collor="#90be6d"
        //collor="#90be6d"
        min={getMin("_T")}
        max={getMax("_T")}
      />
      <OnlySimpleLineChart
        chartData={getData("RV")}
        dataKey="RV"
        title="Humidity(%RV)"
        collor="#e63946"
        min={getMin("RV")}
        max={getMax("RV")}
      />
      <OnlySimpleLineChart
        chartData={getData("SRA1H")}
        dataKey="SRA1H"
        title="Precipitation 1hod(mm)"
        collor="#f8961e"
        min={getMin("SRA1H")}
        max={getMax("SRA1H")}
      />
    </div>
  )
}

DanesSimpleLineChart.propTypes = {
  chartData: PropTypes.any,
}
export default DanesSimpleLineChart
