import { Button, Card, CardBody, Col, Row } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import React from "react"
import { ErrorMessage, Field, Formik } from "formik"
import Switch from "react-switch"
import PropTypes from "prop-types"
import SubjectEditForm from "../Subjects/components/SubjectEditForm"
import * as Yup from "yup"
const FormValidateSchema = Yup.object({
  username: Yup.string()
    .min(4, "Minimu charts is 4")
    .required("This field is required")
    .email("E-mail není ve správném tvaru"),
  firstName: Yup.string()
    .min(3, "Minimu charts is 3")
    .required("This field is required"),
  secondName: Yup.string()
    .min(3, "Minimu charts is 3")
    .required("This field is required"),
  subject: Yup.string()
    .min(3, "Minimu charts is 3")
    .required("This field is required"),
})
const ProfileForm = props => {
  const { data, onSave } = props
  const initialValues = {
    username: data?.data?.username,
    id: data?.data?.id,
    firstName: data?.data?.firstName,
    secondName: data?.data?.secondName,
    password: "",
    phone: data?.data?.phone,
    subject: data?.data?.subject,
    isAdmin: data?.data?.isAdmin,
  }

  return (
    <Card>
      <CardBody>
        <Formik
          initialValues={initialValues}
          validationSchema={FormValidateSchema}
          onSubmit={async values => {
            await onSave(values)
          }}
          render={({ values, isSubmitting, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={6} className="mb-3">
                  <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <Field
                      name="username"
                      type="email"
                      label="User Name"
                      className="form-control"
                      placeholder="Enter User Name"
                    />
                    <ErrorMessage name="username" />
                    <Field name="id" type="hidden" />
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <div className="form-group">
                    <label htmlFor="subject">Subject name</label>
                    <Field
                      name="subject"
                      type="text"
                      label="Subject name"
                      className="form-control"
                      placeholder="Enter subject name"
                    />
                    <ErrorMessage name="subject" />
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <div className="form-group">
                    <label htmlFor="firstName">First name</label>
                    <Field
                      label="First name"
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder="Enter your Name"
                    />
                    <ErrorMessage name="firstName" />
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <div className="form-group">
                    <label htmlFor="secondName">Second name</label>
                    <Field
                      label="Last name"
                      type="text"
                      name="secondName"
                      className="form-control"
                      placeholder="Enter your last name"
                    />{" "}
                    <ErrorMessage name="secondName" />
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <Field
                      label="Phone"
                      type="text"
                      name="phone"
                      className="form-control"
                      placeholder="Enter your phone number"
                    />
                    <ErrorMessage name="phone" />
                  </div>
                </Col>
                <Col md={6} className="mb-3">
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Field
                      label="Password"
                      name="password"
                      className="form-control"
                      placeholder="Set your password"
                      type="password"
                    />
                  </div>
                </Col>

                <div className="text-center mt-4">
                  <Button type="submit" color="success">
                    Save
                  </Button>
                </div>
              </Row>
            </form>
          )}
        />
      </CardBody>
    </Card>
  )
}
export default ProfileForm
ProfileForm.propTypes = {
  onSave: PropTypes.func,
  data: PropTypes.any,
}
