import { Button, Col, Row } from "reactstrap"
import { ErrorMessage, Field, Formik } from "formik"
import React from "react"
import * as Yup from "yup"
import PropTypes from "prop-types"

const FormValidateSchema = Yup.object({
  name: Yup.string()
    .min(4, "Minimu charts is 4")
    .required("This field is required"),
})

const EditItemForm = props => {
  const { onSave, data } = props
  const initialValues = {
    name: data.data.name,
    id: data?.data?.id,
    serial_nr: data.data.serial_nr,
    unique_id: data.data.unique_id,
    location_x: data.data.location_x,
    location_y: data.data.location_y,
    location_name: data.data.location_name,
    location_group: data.data.location_group,
    last_update: data.data.last_update,
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FormValidateSchema}
      onSubmit={async values => {
        await onSave(values)
      }}
      render={({ values, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={12} className="mb-3">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <Field
                  name="name"
                  type="text"
                  label="User Name"
                  className="form-control"
                  placeholder="Enter User Name"
                />
                <ErrorMessage name="name" />
                <Field name="id" type="hidden" />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="form-group">
                <label htmlFor="serial_nr">Serial no</label>
                <Field
                  name="serial_nr"
                  type="text"
                  label="Serial no"
                  className="form-control"
                  placeholder="Enter serial no."
                />
                <ErrorMessage name="serial_nr" />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="form-group">
                <label htmlFor="unique_id">Unique id</label>
                <Field
                  label="Unique id"
                  type="text"
                  name="unique_id"
                  className="form-control"
                  placeholder="Enter Unique id"
                />
                <ErrorMessage name="unique_id" />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="form-group">
                <label htmlFor="location_x">Location x</label>
                <Field
                  label="Location x"
                  type="text"
                  name="location_x"
                  className="form-control"
                  placeholder="Enter location x"
                />{" "}
                <ErrorMessage name="location_x" />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="form-group">
                <label htmlFor="location_y">Location y</label>
                <Field
                  label="Location y"
                  type="text"
                  name="location_y"
                  className="form-control"
                  placeholder="Enter your location_y "
                />
                <ErrorMessage name="location_y" />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="form-group">
                <label htmlFor="location_name">Location name</label>
                <Field
                  label="Location name"
                  name="location_name"
                  className="form-control"
                  placeholder="Set your location name"
                  type="text"
                />
              </div>
            </Col>
            <Col md={6} className="mb-3">
              <div className="form-group">
                <label htmlFor="location_group">Location group</label>
                <Field
                  label="Location group"
                  name="location_group"
                  className="form-control"
                  placeholder="Set your location group"
                  type="text"
                />
              </div>
            </Col>
            <div className="text-center mt-4">
              <Button type="submit" color="success">
                Save
              </Button>
            </div>
          </Row>
        </form>
      )}
    />
  )
}
export default EditItemForm
EditItemForm.propTypes = {
  onSave: PropTypes.func,
  data: PropTypes.any,
}
